import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/guards/auth.service';
import { Routes } from '../../shared/routePath/route';

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    role: string[];
    display: boolean;
}

export const ROUTES: RouteInfo[] = [
    { path: Routes.HOME_DATABASE.URL, title: Routes.HOME_DATABASE.TITLE, icon: '', class: 'icon-DataSource', display: true, role: Routes.HOME_DATABASE.ROLE },
    { path: Routes.DB_LIST.URL, title: Routes.DB_LIST.TITLE, icon: '', class: 'icon-DataSource-Config', display: true, role: Routes.DB_LIST.ROLE },
    { path: Routes.AUDIT_REPORT.URL, title: Routes.AUDIT_REPORT.TITLE, icon: '', class: 'icon-Audit-View', display: true, role: Routes.AUDIT_REPORT.ROLE },
    { path: Routes.USERS.URL, title: Routes.USERS.TITLE, icon: '', class: 'icon-User', display: true, role: Routes.USERS.ROLE },
    { path: Routes.COMPANY.URL, title: Routes.COMPANY.TITLE, icon: '', class: 'icon-Organisation', display: true, role: Routes.COMPANY.ROLE },
    { path: Routes.ROLE.URL, title: Routes.ROLE.TITLE, icon: '', class: 'icon-Role', display: true, role: Routes.ROLE.ROLE },
    { path: Routes.COMPANY_SELF_EDIT.URL, title: Routes.COMPANY_SELF_EDIT.TITLE, icon: '', display: true, class: 'icon-building', role: Routes.COMPANY_SELF_EDIT.ROLE },
    { path: Routes.COMPANY_SETTINGS_SELF_EDIT.URL, title: Routes.COMPANY_SETTINGS_SELF_EDIT.TITLE, icon: '', class: 'icon-building', display: true, role: Routes.COMPANY_SETTINGS_SELF_EDIT.ROLE },
    { path: Routes.LOV.URL, title: Routes.LOV.TITLE, icon: '', class: 'icon-List', display: true, role: Routes.LOV.ROLE },
    
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    constructor(private authService: AuthService) {
        
    }

    ngOnInit() {
        const roleList: string[] = this.authService.getRoleList();
        this.menuItems = ROUTES.filter(menuItem => {
            if (menuItem.role && menuItem.role.length > 0) {
                const roleExistList = menuItem.role.filter(role => roleList.indexOf(role) > -1);
                return roleExistList.length > 0;
            }
            return menuItem;
        });
    }
}
