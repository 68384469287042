<div class="sidebar-wrapper">
  <div class="logo">
    <a routerLink="/" class="simple-text logo-mini">
      <div class="logo-image-small big-logo">
        <img src="assets/img/logo.png">
      </div>
      <div class="logo-image-small mini-logo">
        <img src="assets/img/short-logo.png">
      </div>
    </a>

  </div>
  <ul class="nav">
    <ng-container *ngFor="let menuItem of menuItems">
      <li *ngIf="menuItem.display" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
          false}">
        <a [routerLink]="[menuItem.path]">
          <span class="{{menuItem.class}}"></span>
          <p>{{menuItem.title}}</p>
        </a>
      </li>
    </ng-container>
  </ul>
</div>