import {
  HttpEvent, HttpHandler,

  HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { ToastrMessageService } from '../services/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, private router: Router, private toastrService: ToastrMessageService) { }

  removeRequest(req: HttpRequest<any>) {
    const i: number = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.loaderService.isLoading.next(true);
    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
              if (event && event.body && event.body.actionStatus === 'ERROR') {
                if (event.body.message) {
                  this.toastrService.triggerNotification(event.body.message, 'error');
                }
                else if (event.body.actionResult.errors) {
                  const errors: Array<string> = (event.body.actionResult.errors || []).map(error => error.description);
                  this.toastrService.triggerNotification(errors.join("<br/>"), 'error');
                }
              }
            }
          },
          err => {
            console.log(err, req);
            this.removeRequest(req);
            observer.error(err);
            if (err.status === 401) {
              const message: string = err.error.message || (err.error.path === '/authenticate' ? 'Invalid email or password' : 'Please login to access system');
              this.toastrService.triggerNotification(message, 'error');
              sessionStorage.setItem('lastURL', window.location.href);
              this.router.navigate(['/login']);
            }
            else if (err.status === 400) {
              this.toastrService.triggerNotification('Invalid Request Parameter', 'error');
            }
            else if (err.status === 403) {
              this.toastrService.triggerNotification('You are not authorized user, to see this page.', 'error');
              this.router.navigate(['/']);
            }
            else if (err.status === 404) {
              this.toastrService.triggerNotification('Oops, something goes wrong, please try again later.', 'error');
            }            
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
