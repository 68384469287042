const redirectURL = window.location.origin+'/login/callback';

export const environment = {
  production: false,
  apiStatic: {
    "protocol": "https",
    "url": "dev-api.thedataeditor.com",
    endpoint: ''
  },
  
  oktaLoginConf : {
    allowedTDEDDomain:['dev-karl.thedataeditor.com', 'karl.thedataeditor.com', 'localhost:4200'],
    domain: 'karllagerfeld.oktapreview.com',
    audience: 'karllagerfeld.oktapreview.com',
    scope: 'openid offline_access profile email',
    response_type: 'code',
    clientId: '0oa7lj8jzgsIQdOsu0x7',
    redirectURL,
    connection: 'main-tenant-oidc',
    state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601',
    code_challenge_method: 'S256',
    code_challenge: 'qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es'
  }
};
