<!-- <ng-container *ngIf="paramsData">
    <ng-container *ngFor="let param of paramsData;let i= index">
        <ng-container
            *ngIf="!(param?.displayValue) || 
            ((param?.displayValue?.value === params.data[param?.displayValue?.key] && params?.data?.id) &&
            (!(param?.nonDisplayValue) || 
            (param?.nonDisplayValue && param?.nonDisplayValue[0]?.value !== params.data[param?.nonDisplayValue[0]?.key])
            && (param?.nonDisplayValue && param?.nonDisplayValue[1]?.value !== params.data[param?.nonDisplayValue[1]?.key])
            ))">
            <button (click)="(param?.displayValue?.dbType === 'MySQL' && !params.data.isPrimaryKey) ? '' : onClick($event, i)" class="btn btn-link {{ param?.icons?.buttonTextClass}}" 
                [ngClass]="{'text-primary': !(param?.icons?.buttonClass), 'p-2': !(param?.icons?.noDefaultPaddding), 'pointer-alt': (param?.icons?.noPointer) }" [title]="param?.icons?.title || ''">
                <i [ngClass]="param?.icons?.iconClass" [class]="param?.icons?.buttonClass"
                    style="font-size: 14px;"></i>
                <abbr *ngIf="this.text"> {{this.text}}</abbr>
            </button>
        </ng-container>
    </ng-container>
</ng-container> -->

<ng-container *ngIf="paramsData">
    <ng-container *ngFor="let param of paramsData;let i= index">
        <ng-container
            *ngIf="((param?.permanentNoDisplayValue && (param?.permanentNoDisplayValue?.value != params.data[param?.permanentNoDisplayValue?.key])) || !(param?.permanentNoDisplayValue)) && 
            ((!(param?.displayValue) || 
            ((param?.displayValue?.value === params.data[param?.displayValue?.key] && ((param?.uniqueId && params?.data[param?.uniqueId]) || params?.data?.id)) &&
            (!(param?.nonDisplayValue) || 
            (param?.nonDisplayValue && param?.nonDisplayValue[0]?.value !== params.data[param?.nonDisplayValue[0]?.key])
            && (param?.nonDisplayValue && param?.nonDisplayValue[1]?.value !== params.data[param?.nonDisplayValue[1]?.key])
            ))))">
            <button (click)="(param?.displayValue?.dbType === 'MySQL' && !params.data.isPrimaryKey) ? '' : onClick($event, i)" class="btn btn-link {{ param?.icons?.buttonTextClass}}" 
                [ngClass]="{'text-primary': !(param?.icons?.buttonClass), 'p-2': !(param?.icons?.noDefaultPaddding), 'pointer-alt': (param?.icons?.noPointer) }" [title]="param?.icons?.title || ''">
                <i [ngClass]="param?.icons?.iconClass" [class]="param?.icons?.buttonClass"
                    style="font-size: 14px;"></i>
                <abbr *ngIf="this.text"> {{this.text}}</abbr>
            </button>
        </ng-container>
    </ng-container>
</ng-container>