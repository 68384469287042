import { Injectable } from '@angular/core';
import { Observable, Subject,  of } from 'rxjs';
// import 'rxjs/add/observable/of';
import 'rxjs/add/operator/share';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  private mastersList: object = {};
  private masterURLs: object = {
    ROLE_GROUP: 'role/list',
    DB_TYPE: 'master/dbType'
  };
  mastersListObservable: any = {};

  constructor(private httpService: HttpService) { }

  getMastersList(key: string): Observable<any> {
    if (this.mastersList[key] && this.mastersList[key].data) {
      // if `data` is available just return it as `Observable`
      return of(this.mastersList[key].data);
    } else if (this.mastersList[key] && this.mastersList[key].observable) {
      // if `this.observable` is set then the request is in progress
      // return the `Observable` for the ongoing request
      return this.mastersList[key].observable;
    } else {
      this.mastersList[key] = {};
      this.mastersList[key].observable = this.httpService.get(this.masterURLs[key]).pipe(
        map((response) => {
          this.mastersList[key].data = response.responseBody;
          return this.mastersList[key].data;
        })
      );
      return this.mastersList[key].observable;
    }
  }
}
