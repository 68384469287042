// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'tde-select-box-renderer',
  templateUrl: './select-box-renderer.component.html',
  styleUrls: ['./select-box-renderer.component.scss']
})

export class SelectBoxRendererComponent implements ICellRendererAngularComp {
  value;
  params;
  label: string;
  listData = [];
  optGroup = '';
  displaySelectBox: boolean = true;

  agInit(params): void {
    this.params = params;
    if (params.keyCheck) {
      this.displaySelectBox = params.data[params.keyCheck.field] == params.keyCheck.expectedValue;
    }
    this.listData = params.selectData || [];
    this.optGroup = params.hasOwnProperty('groupBy') ? (params.groupBy || '') : '';
    this.label = this.params.label || "--Select--";
    if (this.params.value !== null && this.params.value !== "") {
      this.value = this.listData && this.listData.find(ld => ld.id == this.params.value);
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  change($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        node: this.params,
        value: this.value
      }
      this.params.onClick(params);
    }
  }
}