import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Constants } from "../utils/constants";

@Injectable({
  providedIn: "root",
})
export class CommonUtilsService {
  constructor(private datePipe: DatePipe) {}

  convertToDateTime(value: string): string {
    if (value) {
      value = this.datePipe.transform(value, Constants.DATE_TIME_FORMAT);
    }
    return value;
  }
}
