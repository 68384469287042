import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { httpInterceptorProviders } from "./index";


@NgModule({
    imports: [
        HttpClientModule
    ]
})
export class HttpClientInterceptorModule {
    static forRoot() {
        return {
            ngModule: HttpClientInterceptorModule,
            exports: [HttpClientModule],
            providers: [
                httpInterceptorProviders
            ]
        }
    }
}