import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './shared/component/loader/loader.component';
import { AuthService } from './shared/guards/auth.service';
import { HttpClientInterceptorModule } from './shared/interceptor/http.client.interceptor.module';
import { LoaderInterceptor } from './shared/interceptor/loader-interceptor.service';
import { HttpService } from './shared/services/http.service';
import { LoaderService } from './shared/services/loader.service';
import { MasterService } from './shared/services/masters.service';
import { ToastrMessageService } from './shared/services/toastr.service';
import { UrlService } from './shared/services/url.service';
import { SharedModule } from './shared/shared.module';
import { OktaLoginService } from './shared/guards/okta.login.service';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    NgSelectModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    HttpClientInterceptorModule.forRoot(),
    SharedModule
  ],
  exports: [
    LoaderComponent
  ],
  providers: [
    ToastrMessageService,
    HttpService,
    UrlService,
    LoaderService,
    AuthService,
    OktaLoginService,
    MasterService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
