<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <div class="navbar-minimize">
        <button class="btn btn-icon btn-round" id="minimizeSidebar" (click)="sidebarWebToggle()">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)">{{ pageTitle || '' }}</a>
    </div>
    <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav">
        <!-- <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem href="javascript:void(0)">Notification</a>
          </div>
        </li> -->
        <li class="nav-item "><span class="icon-profile pr-1"></span>{{userName}}</li>
        <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
            <i class="nc-icon nc-settings-gear-65"></i>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem [routerLink]="['/change-password']" *ngIf="allowedChangePswd"><span class="icon-padlock"></span> Change Password</a>
            <a ngbDropdownItem [routerLink]="['/logout']"><span class="icon-logout"></span>Log Out</a>
          </div>
        </li> 
        <!-- <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a class="nav-link btn-rotate" href="javascript:void(0)">
            <i class="fa fa-sign-out"></i>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>