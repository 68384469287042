import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter, HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { AgGridService } from '../library/service/ag-grid.service';
import { Constants } from '../../../utils/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterChangedEvent } from 'ag-grid';
import { TableGridFilterService } from './table-grid-filter.service';
import { CustomNoRowsOverlayComponent } from './custom-no-rows-overlay.component';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { TableDataService } from 'src/app/shared/services/table-data.service';

declare let $: any;
@Component({
  selector: 'tde-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableGridComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() params: object = {};
  @Input() heightUpdate;
  @Input() pagination = true;
  @Input() frameworkComponents;
  @Input() gridConfig = {};
  @Output() gridApiChanges: EventEmitter<any> = new EventEmitter();

  @Input() tableList = { rowData: [], columnsDef: [] };
  @ViewChild('agGridContainer') agGridContainer: ElementRef;
  @ViewChild('agGrid') agGrid: ElementRef;

  private updateDataIds: Set<string> = new Set<string>();

  public gridParentId: string = '';
  public maxHeight: string = 'calc(45vh - 100px)';
  public isMaxHeightCalculated: boolean = false;
  public defaultColDef = {};
  public undoRedoCellEditingLimit = 5;
  public gridApi: any;
  public gridColumnApi: any;
  public gridOptions;

  public columnsDef;
  public rowData;
  

  public paginationSize: number = Constants.DEFAULT_PAGINATION_SIZE;
  public paginationAutoPageSize: boolean = false;

  public gridHeight: number = 0;

  private isContentToFit: boolean = false;
  public isSizeToFit: boolean = false;
  public noRowsOverlayComponent;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private agGridService: AgGridService,
    private readonly route: ActivatedRoute,
    private readonly excelService: ExcelService,
    private readonly tableFilterService: TableGridFilterService,
    private readonly tableDataService: TableDataService
  ) {
    // this.frameworkComponents = {...this.frameworkComponents,
    //   customNoRowsOverlay: CustomNoRowsOverlayComponent
    // };
    this.noRowsOverlayComponent = 'customNoRowsOverlay';

    this.gridOptions = {
      suppressPropertyNamesCheck: true,
      defaultColDef: {
        resizable: true
      },
      //suppressCellSelection: true
      onFilterChanged: event => this.filterChanged(event),
    };
    //this.gridOptions.defaultColDef['sortable'] = true;
  }
  public components;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateHeight();
  }

  ngOnInit() {
    this.elementObserver();
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
    };
    this.components = {
      datePicker: getDatePicker(),
      dateTimepicker: getDateTimePicker(),
      timePicker: getTimePicker(),
    };
  }

  filterChanged(event: FilterChangedEvent) {
    const filterModel = event.api.getFilterModel();
    if (this.route.snapshot.data.TITLE !== 'Datasource') {
      this.tableFilterService.persistFilters(filterModel);
      this.tableFilterService.currentRoute = (this.route.snapshot.data && this.route.snapshot.data.URL && this.route.snapshot.data.URL.split("/")[1]) || "";
      this.tableFilterService.currentRouteExt = (this.route.snapshot.data && this.route.snapshot.data.URL && this.route.snapshot.data.URL.length > 3 && this.route.snapshot.data.URL.split("/")[3]) || "";
    }
  }

  disablePagination() {
    this.paginationSize = 0;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
  }

  contentFitOnScroll(): void {
    setTimeout(() => {
      const scrollViewportSelector: string = '.ag-body-horizontal-scroll-viewport';
      if (this.agGridContainer) {
        this.agGridContainer.nativeElement.querySelector(scrollViewportSelector).addEventListener("scroll", () => {
          this.autoSizeAll(false);
        });
      }
    }, 0);
  }

  elementObserver() {
    const ro = new ResizeObserver((entries) => {
      if (this.gridApi) {
        for (const entry of entries) {
          this.callColumnFit();
          this.calculateHeight();
        }
      }
    });
    // Element for which to observe height and width
    if (this.agGridContainer) {
      ro.observe(this.agGridContainer.nativeElement);
    }
  }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.callColumnFit();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.callColumnFit();
      });
    });

    if (!this.isMaxHeightCalculated) {
      this.gridApi.setDomLayout('autoHeight');
    }
    if (this.route.snapshot.data.TITLE !== 'Datasource') {
      const filters = this.tableFilterService.getExistingFilters();
      filters && this.gridApi.setFilterModel(filters);
    }
  }

  callColumnFit(skipHeader: boolean = false) {
    if (this.isSizeToFit) {
      this.sizeToFit();
    } else {
      if (this.isContentToFit) {
        this.autoSizeAll(skipHeader);
      }
    }
  }

  ngAfterViewInit() {
    this.calculateHeight();
    this.autoSizeAll(false);
  }


  autoSizeAll(skipHeader) {
    if (!this.gridColumnApi) {
      return;
    }
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }


  onCellValueChanged(params: any) {
    this.updateDataIds.add(params.node.id);
    this.agGridService.setTableListUpdateData(params.node);
    this.gridApiChanges.emit(this.gridApi);
  }

  onRowSelected(event) {
    this.updateDataIds.add(event.node.data.id);
    event.node.data.checked = event.node.selected;
    this.agGridService.setTableListUpdateData(event.node);
  }

  onSelectionChanged(event) {

  }

  calculateHeight() {
    this.isMaxHeightCalculated = false;
    this.maxHeight = null;
    if (
      typeof this.gridParentId !== undefined &&
      document.getElementById(this.gridParentId) &&
      document.getElementById(this.gridParentId).clientHeight
    ) {
      const height: number =
        document.getElementById(this.gridParentId).clientHeight - 10;
      if (height > 10) {
        this.maxHeight =
          document.getElementById(this.gridParentId).clientHeight - 10 + 'px';
      }
      this.isMaxHeightCalculated = true;
      this.changeDetector.detectChanges();
    }
  }



  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    this.frameworkComponents = {
      ...this.frameworkComponents,
      customNoRowsOverlay: CustomNoRowsOverlayComponent
    };

    if (changes.gridConfig?.currentValue?.rowClassRules) {
      this.gridOptions.rowClassRules = changes.gridConfig?.currentValue?.rowClassRules;
      this.gridOptions = { ...this.gridOptions };
      this.changeDetector.detectChanges();
    }

    if (changes.params) {
      const currentValue = changes.params.currentValue;
      this.isContentToFit = currentValue.contentToFit || false;
      this.isSizeToFit = currentValue.sizeToFit || false;
      this.callColumnFit(true);

      this.gridParentId = currentValue.gridParentId || '';

      if (currentValue.heightUpdate) {
        this.calculateHeight();
      }

      if (currentValue.contentAutoFitOnScroll) {
        this.contentFitOnScroll();
      }

      if (currentValue.isPaginated || (currentValue.pagination && currentValue.pagination.isPaginated)) {
        if (currentValue.pagination.autoPagination) {
          this.paginationAutoPageSize = true;
        }
        else if (changes.tableList.currentValue.rowData.length > (currentValue.paginationSize || currentValue.pagination.paginationSize || Constants.DEFAULT_PAGINATION_SIZE)) {
          this.paginationSize = currentValue.paginationSize || currentValue.pagination.paginationSize || Constants.DEFAULT_PAGINATION_SIZE;
        }
      }
    }

    if (changes.tableList) {
      const tableList = changes.tableList.currentValue;
      tableList.columnsDef.forEach(columnDef => {
        if (columnDef.checkFilterSort) {
          const foundValue = tableList.rowData.find(row => columnDef.field && row[columnDef.field] && row[columnDef.field].toString().trim() != "");
          if (!foundValue) {
            columnDef.sortable = false;
            columnDef.filter = false;
          }
        }
        columnDef.filterParams = {
          newRowsAction: 'keep'
        }
        if (columnDef.field === 'createdAt') {
          columnDef.sort = 'desc'
        }
        if (columnDef.sortable && columnDef.filter === Constants.DATE_FILTER) {
          columnDef.comparator = (date1, date2, nodeA, nodeB, isInverted) => {
            if (date1 === date2) {
              return 0;
            }
            else if (date1 == null) {
              return -1;
            }
            else if (date2 == null) {
              return 1;
            }
            else {
              date1 = new Date(date1);
              date2 = new Date(date2);
              return date1 - date2;
            }
          }
        }

        if (columnDef.filter && columnDef.filter === Constants.DATE_FILTER) {
          columnDef.filterParams = {
            newRowsAction: 'keep',
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              if (cellValue === null) {
                return 0;
              }
              let cellDate = new Date(cellValue);
              cellDate = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            }
          }
        }
      });
      this.columnsDef = tableList.columnsDef;
      this.rowData = tableList.rowData;
    }

    this.callColumnFit();
  }

  setPaginaztionSize(paginationSize: number) {
    paginationSize = +paginationSize;
    this.paginationSize = paginationSize;
    this.gridOptions.api.gridOptionsWrapper.setProperty('cacheBlockSize', paginationSize);
    this.gridOptions.api.paginationSetPageSize(paginationSize);
  }

  onModelUpdated($event) {
    if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length == 0) {
      this.gridApi.showNoRowsOverlay();
    }
    if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length > 0) {
      this.gridApi.hideOverlay();
    }
  }

  sizeToFit() {
    if (!this.gridApi) {
      return;
    }
    this.gridApi.sizeColumnsToFit();
  }


  processCellForClipboard(params) {
    return 'C-' + params.value;
  }

  processHeaderForClipboard(params) {
    return 'H-' + params.column.getColDef().headerName;
  }

  processCellFromClipboard(params) {
    return 'Z-' + params.value;
  }

  exportData(isCsv: boolean = false, fileName: string = '', columnSeparator: string = ';', columnKeys = {}): void {
    if (!fileName) {
      fileName = 'export';
    }
    this.gridColumnApi.getAllGridColumns()
      .filter(column => column.colDef.field).map(column => {
        columnKeys[column.colDef.field] = column.colDef.headerName;
      });

    if (!isCsv) {
      const rowData = [];
      this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
        rowData.push(rowNode.data);
      });
      this.excelService.downloadFile({ columnsDef: this.tableList.columnsDef, rowData }, fileName, this.tableDataService.getTablePrimaryKeys(), false);
    }
    else {
      this.gridApi.exportDataAsCsv({ fileName, columnKeys, columnSeparator });
    }
  }

}


function getDatePicker() {
  function Datepicker() { }
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.setAttribute('readonly', 'readonly');
    this.eInput.value = params.value;
    this.eInput.classList.add('ag-input');
    this.eInput.style.height = '100%';
    $(this.eInput).datepicker({ dateFormat: 'yy-mm-dd' });
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Datepicker.prototype.destroy = function () { };
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
}

function getDateTimePicker() {
  function DateTimepicker() { }
  DateTimepicker.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.setAttribute('readonly', 'readonly');
    this.eInput.value = params.value;
    this.eInput.classList.add('ag-input');
    this.eInput.style.height = '100%';
    console.log('config - ', JSON.parse(sessionStorage.getItem('configSettings')));
    const dateFormat = JSON.parse(sessionStorage.getItem('configSettings')).dateFormat;
    const timeFormat = JSON.parse(sessionStorage.getItem('configSettings')).timeFormat;
    const dateTimeFormat = JSON.parse(sessionStorage.getItem('configSettings')).dateTimeFormat;
    const setDate1 = delimiterCheck(this.eInput.value) === 'NDE' || this.eInput.value === null ? (this.eInput.value === null ? this.eInput.value : new Date(this.eInput.value)) : toDate(this.eInput.value, dateFormat, delimiterCheck(this.eInput.value));
    console.log('dateTimeConversion-', dateTimeConversion(dateFormat));
    $(this.eInput).datetimepicker({
      dateFormat: dateTimeConversion(dateFormat),
      timeFormat: timeFormat.replace('a', 'TT'),
      showButtonPanel: true,
    }).datetimepicker("setDate", setDate1)
      .mask(dateTimeFormat.replace(/a/g, 'aa').replace(/MMM/g, 'aaa').replace(/[^0-9\-\/\:\ \a]/g, '9'), { placeholder: '_' })
  };
  DateTimepicker.prototype.getGui = function () {
    return this.eInput;
  };
  DateTimepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  DateTimepicker.prototype.getValue = function () {
    this.eInput.blur();
    return this.eInput.value;
  };
  DateTimepicker.prototype.destroy = function () { };
  DateTimepicker.prototype.isPopup = function () {
    return false;
  };
  return DateTimepicker;
}

function toDate(date, format, delimiter) {
  var date = date;
  var formatedDate = null;
  var formatLowerCase = format.toLowerCase();
  var formatItems = formatLowerCase.split(delimiter);
  var dateItems = date.split(delimiter);
  var monthIndex = formatItems.indexOf("mm");
  var monthNameIndex = formatItems.indexOf("mmm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var yearShortIndex = formatItems.indexOf("yy");
  var d = dateItems[dayIndex].substring(0, 2);

  var timeParts = evaluateTimeIndex(dateItems, monthIndex, monthNameIndex, dayIndex, yearIndex, yearShortIndex);
  const tempYearIndex = yearIndex > -1 ? yearIndex : yearShortIndex;
  const yearCharLength = yearIndex > -1 ? 4 : 2;
  if (d < 10) {
    d = "0" + d;
  }
  if (monthIndex > -1) {
    var month = (dateItems[monthIndex].substring(0, 2));
    month -= 1;
    if (month < 10) {
      month = "0" + month;
    }
    formatedDate = new Date(+new Date(dateItems[tempYearIndex].substring(0, yearCharLength), month, d) + parseDaytime(timeParts));
  } else if (monthNameIndex > -1) {
    var monthName = dateItems[monthNameIndex].substring(0, 3);
    month = getMonthIndex(monthName);
    if (month < 10) {
      month = "0" + month;
    }
    formatedDate = new Date(+new Date(dateItems[tempYearIndex].substring(0, yearCharLength), month, d) + parseDaytime(timeParts));
  }
  return formatedDate;
};

function getMonthIndex(name) {
  name = name.toLowerCase();
  if (name == "jan" || name == "january") {
    return 0;
  } else if (name == "feb" || name == "february") {
    return 1;
  } else if (name == "mar" || name == "march") {
    return 2;
  } else if (name == "apr" || name == "april") {
    return 3;
  } else if (name == "may" || name == "may") {
    return 4;
  } else if (name == "jun" || name == "june") {
    return 5;
  } else if (name == "jul" || name == "july") {
    return 6;
  } else if (name == "aug" || name == "august") {
    return 7;
  } else if (name == "sep" || name == "september") {
    return 8;
  } else if (name == "oct" || name == "october") {
    return 9;
  } else if (name == "nov" || name == "november") {
    return 10;
  } else if (name == "dec" || name == "december") {
    return 11;
  }
}

// function toDate(dateStr) {
//   var parts = dateStr.split("-");
//   var timeParts = parts[2].substring(5).split(":");
//   // return new Date(parts[2].substring(0,4), parts[1] - 1, parts[0], timeParts[0], timeParts[1].split(" ")[0])
//   return new Date(+new Date(parts[2].substring(0,4), parts[1] - 1, parts[0])
//   +parseDaytime(parts[2].substring(5)))
// }
function parseDaytime(time) {
  let [hours, minutes, seconds] = time.substr(0, time.length - 2).split(":").map(Number);
  if (time.includes("PM") && hours !== 12) hours += 12;
  return (seconds !== undefined ? (1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes + (seconds * 1 / 60))) : (1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes)));
}
function getTimePicker() {
  function Timepicker() { }
  Timepicker.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.setAttribute('readonly', 'readonly');
    this.eInput.value = params.value;
    this.eInput.classList.add('ag-input');
    this.eInput.style.height = '100%';
    $(this.eInput).timepicker({
      timeFormat: Constants.TIME_FORMAT,
      showButtonPanel: false,
      showSecond: null,
      showMillisec: null,
      showMicrosec: null,
      showTimezone: null,
    });
  };
  Timepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Timepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Timepicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Timepicker.prototype.destroy = function () { };
  Timepicker.prototype.isPopup = function () {
    return false;
  };
  return Timepicker;
}

function delimiterCheck(date) {
  var format = /[\\]/;
  var delimiter = /[\-]/;
  if (format.test(date)) {
    return '/';
  } else if (delimiter.test(date)) {
    return '-';
  } else {
    return 'NDE' /*No Delimiter Exists*/
  }
}

function evaluateTimeIndex(dateItems, monthIndex, monthNameIndex, dayIndex, yearIndex, yearShortIndex) {
  var list = [{ monthIndex, length: '2' }, { monthNameIndex, length: '2' }, { dayIndex, length: '2' }, { yearIndex, length: '4' }, { yearShortIndex, length: '2' }];
  let time;
  Object.keys(list).forEach(key => {
    Object.keys(list[key]).forEach(keys => {
      if (list[key][keys] === 2 && keys !== 'length') {
        const index = list[key][keys];
        const substrlth = list[key]['length'];
        time = dateItems[index].substring(+substrlth + 1);
      }
    })
  })
  return time;
}

function dateTimeConversion(dateFormat) {
  const delimiter = delimiterCheck(dateFormat)
  const formatLowerCase = dateFormat.toLowerCase();
  const formatItems = formatLowerCase.split(delimiter);
  const dateItems = dateFormat.split(delimiter);
  const monthIndex = formatItems.indexOf("mm");
  const monthNameIndex = formatItems.indexOf("mmm");
  const yearIndex = formatItems.indexOf("yyyy");
  const yearShortIndex = formatItems.indexOf("yy");
  dateFormat = dateFormat.replace('dd', 'd');
  dateFormat = monthIndex !== -1 ? dateFormat.replace('MM', 'mm') : dateFormat;

  const formatArray = [{ key: 'dd', value: 'd' }, { key: 'MMM', value: 'M' }, { key: 'MMMM', value: 'MM' },
  { key: 'yy', value: 'y' }, { key: 'yyy', value: 'yy' }];
  formatArray.forEach(data => {
    if (dateFormat.indexOf(data.key) > -1) {
      dateFormat = dateFormat.replace(data.key, data.value);
    }
  });
  return dateFormat;
}
