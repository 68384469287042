import { AfterViewInit, Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'tde-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit {

  public loading: boolean = false;
  constructor(private loaderService: LoaderService) { }

  ngAfterViewInit(): void {
    this.loaderService.isLoading.subscribe((v) => {
      setTimeout(() => {
        this.loading = v;
      }, 0);
    });
  }
}
