import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivationStart, ActivationEnd } from '@angular/router';
import { ROUTES } from './../../main/sidebar/sidebar.component';
import { filter } from 'rxjs/operators';
import { LoginList } from '../../shared/utils/constants';

@Component({
  moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
  public pageTitle: string = '';
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  public userName: string;
  public allowedChangePswd: boolean;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;

  constructor(location: Location, private renderer: Renderer2, private element: ElementRef, private router: Router) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;    
    this.getAllowedLogins();
    this.router.events
      .pipe(filter(event => event instanceof ActivationEnd))
      .subscribe(event => {
        this.setPageTitle(event['snapshot'].data || {});
      });
  }

  ngOnInit() {
    this.userName = sessionStorage.getItem('userName');
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      if (event instanceof ActivationStart) {
        this.setPageTitle(event['snapshot'].data || {});
      }
    });
    this.getDrawerStatus()
  }
  
  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarWebToggle() {
    const bodyClass = document.getElementsByTagName('body')[0].className;
    if (bodyClass.indexOf("sidebar-mini") == -1) {
      document.getElementsByTagName('body')[0].classList.add("sidebar-mini");
      sessionStorage.setItem('hideDrawer',"true");
    }
    else {
      document.getElementsByTagName('body')[0].classList.remove("sidebar-mini");
      sessionStorage.setItem('hideDrawer',"false");
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };


  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }
  }

  setPageTitle(data): void {
    if (data.TITLE) {
      this.pageTitle = data.TITLE;
    }
  }

  getAllowedLogins() {
    this.allowedChangePswd = false;
    const loginList = LoginList.LOGINTYPES.map(data => data.name);
    const configSettings = JSON.parse(sessionStorage.getItem('configSettings'));
    loginList.forEach((data,index) => {
      if(configSettings && configSettings.hasOwnProperty(data) && configSettings[data] && data === 'isLoginEmailAllowed') {
        this.allowedChangePswd = true;
      } 
    }); 
  }

  getDrawerStatus(){
    let status = sessionStorage.getItem('hideDrawer');
    if(status && status == 'true'){
      sessionStorage.setItem('hideDrawer',"true");
      document.getElementsByTagName('body')[0].classList.add("sidebar-mini");
    }else{
      sessionStorage.setItem('hideDrawer',"false");
      document.getElementsByTagName('body')[0].classList.remove("sidebar-mini");
    }
  }
}
