import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCommonModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonRendererComponent } from './component/ag-grid/library/component/button-renderer/button-renderer.component';
import { CheckboxRendererComponent } from './component/ag-grid/library/component/checkbox-renderer/checkbox-renderer.component';
import { BootstrapDatePickerComponent } from './component/ag-grid/library/component/date-renderer/bootstrapDatePicker.component';
import { CustomDateComponent } from './component/ag-grid/library/component/date-renderer/custom.date.component';
import { HeaderRendererComponent } from './component/ag-grid/library/component/header-renderer/header-renderer.component';
import { IconRendererComponent } from './component/ag-grid/library/component/icon-renderer/icon-renderer.component';
import { LinkRendererComponent } from './component/ag-grid/library/component/link-renderer/link-renderer.component';
import { SelectBoxRendererComponent } from './component/ag-grid/library/component/select-box-renderer/select-box-renderer.component';
import { CustomNoRowsOverlayComponent } from './component/ag-grid/table-grid/custom-no-rows-overlay.component';
import { TableGridComponent } from './component/ag-grid/table-grid/table-grid.component';
import { MsgNotificationComponent } from './component/msg-notification/msg-notification.component';
import { CommonUtilsService } from './services/common-utils.service';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        MatCommonModule,
        MatCheckboxModule,
        MatIconModule,
        NgSelectModule,
        AgGridModule.withComponents(
            [
                ButtonRendererComponent,
                IconRendererComponent,
                BootstrapDatePickerComponent,
                CustomDateComponent,
                HeaderRendererComponent,
                LinkRendererComponent,
                CheckboxRendererComponent,
                SelectBoxRendererComponent,
                CustomNoRowsOverlayComponent
            ]
        ),
        // , CustomDateComponent, DatePickerEditorComponent]),
    ],
    declarations: [
        CustomNoRowsOverlayComponent,
        TableGridComponent,
        ButtonRendererComponent,
        IconRendererComponent,
        HeaderRendererComponent,
        LinkRendererComponent,
        CheckboxRendererComponent,
        SelectBoxRendererComponent,
        MsgNotificationComponent
    ],
    exports: [
        CustomNoRowsOverlayComponent,
        TableGridComponent,
        ButtonRendererComponent,
        IconRendererComponent,
        HeaderRendererComponent,
        LinkRendererComponent,
        CheckboxRendererComponent,
        SelectBoxRendererComponent,
        MsgNotificationComponent
    ],
    providers: [
        CommonUtilsService,
        DatePipe
    ]
})

export class SharedModule { }
