import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string = '';
  private roleList: string[] = [];

  constructor() { }

  getToken(): string {
    const token: string = sessionStorage.getItem('a');
    return this.token || token;
  }

  setToken(token: string, roleList: string[], firstName: string, lastName: string, configSettings: object = {}, emailId: string) {
    roleList = roleList || [];
    if (token) {
      sessionStorage.setItem('a', token);
      sessionStorage.setItem('b', roleList.toString());
      sessionStorage.setItem('userName', `${firstName} ${lastName}`);
      sessionStorage.setItem('firstName', firstName);
      sessionStorage.setItem('configSettings', JSON.stringify(configSettings));
      sessionStorage.setItem('emailId', emailId);
    }
    else {
      token = '';
      sessionStorage.clear();
    }
    this.token = token;
    this.roleList = roleList;
  }

  setOktaToken(token: string, roleList: string[], firstName: string, lastName: string, configSettings: object = {}, emailId: string, refreshToken: string) {
    this.setToken(token, roleList, firstName, lastName, configSettings, emailId);
    if (refreshToken) {
      sessionStorage.setItem('rt', refreshToken);
    }
  }

  getRoleList(): string[] {
    const roleList: string = sessionStorage.getItem('b');
    if (roleList) {
      if (this.roleList.length === 0) {
        this.roleList = roleList.split(',');
      }
      return roleList.split(',');
    }
    return this.roleList;
  }

  getConfigSettings(settingName: string): string | object {
    try {
      const configSettings = JSON.parse(sessionStorage.getItem('configSettings'));
      return (configSettings && configSettings[settingName]) || null;
    }
    catch (e) {
      return null;
    }
  }

  hasPermission(roleName: string): boolean {
    return this.getRoleList().indexOf(roleName) > -1;
  }
}
