import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  private EXCEL_TYPE: string = 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8';
  private EXCEL_EXTENSION: string = '.xlsx';
  private STYLE_PRIMARY_KEY_AUTO_GENERATED = {
    fontColor: '000000',
    bgColor: 'e0f6f6'
  };

  private STYLE_PRIMARY_KEY_NON_AUTO_GENERATED = {
    fontColor: '000000',
    bgColor:'1dc7ea'
  };

  constructor() { }

  public downloadFile(excelData, fileName: string, primaryKeys: any[], firstRowOnly: boolean): void {
    //Excel Title, Header, Data
    const tableHeading = [];
    const primaryKeysObject = {};
    console.log('excelData-', excelData);
    primaryKeys.forEach(primaryKey => {
      primaryKeysObject[primaryKey.columnName] = primaryKey;
    });
    
    excelData.columnsDef.forEach((column, indexPos) => {
      const field: string = column.field || column.columnName;
      const headerName: string = column.headerName || column.columnTitle || column.columnName;
      if (primaryKeysObject.hasOwnProperty(field)) {
        let style = primaryKeysObject[field].isAutoGenerated ? this.STYLE_PRIMARY_KEY_AUTO_GENERATED : this.STYLE_PRIMARY_KEY_NON_AUTO_GENERATED;
        if(column.cellStyle){
          style = column.cellStyle
        }
        tableHeading.unshift({name:headerName, key:field, lockPosition:column.lockPosition, style:style, isPrimaryKey: true});
      }else{
        tableHeading.push({name:headerName, key:field, lockPosition:column.lockPosition, style:column.cellStyle, isPrimaryKey: false});
      }
    });
  
    const data = (excelData.rowData || []).length > 0 ? (firstRowOnly ? [excelData.rowData[0]] : excelData.rowData) : [];
   
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('data');
    

    let list = [];
    data.forEach(row => {
      let eachRow = [];
      tableHeading.forEach((headers) => {
        eachRow.push(row[headers.key])
      })
      list.push(eachRow);
    });

    let alphaIndex = this.numberToLetters(tableHeading.length)
     // Add a table
    const table = worksheet.addTable({
        name: 'MyTable',
        displayName: 'Table1',
        ref: `A1:${alphaIndex}${data.length}`, // Reference to the data range
        style:{
          theme: 'TableStyleMedium2',
          showRowStripes:true,
          showColumnStripes: false
        },
        headerRow: true,
        columns: tableHeading.map(item => ({ name: item.name })),
        rows: [...list]
    });
    // Set column width based on content
    worksheet.columns.forEach((column, columnIndex) => {
      let maxLength = 0;
      //column.protection = { locked: false };
      column.eachCell((cell,cellNum)=>{
        const cellValue = cell.value;
        const cellLength = cellValue ? String(cellValue).length : 0;
        maxLength = Math.max(maxLength, cellLength);
        //cell.protection = { locked: false };
        if(cellNum <=  list.length+1){
          if(tableHeading[columnIndex].lockPosition || cellNum == 1){
            cell.protection = { locked: true };
          }
        // }
        // if(cellNum < list.length+2){
          
        //   if(!tableHeading[columnIndex].lockPosition && cellNum >1){
        //     cell.protection = { locked: false };
        //   }

          // style cell
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          if(cellNum == 1){
            cell.fill = {
              type: 'pattern',  
              pattern: 'solid',
              fgColor:{ argb: '51cbce' },
            }
            cell.font = {bold: true,  color: { argb: 'FFFFFF'}};
            cell.alignment ={ horizontal: 'center' };
          }else{
            cell.alignment = { horizontal: 'left', vertical: 'middle' };
          }
          if(cellNum>2 && cellNum%2 !== 0){
            cell.fill = {
              type: 'pattern',  
              pattern: 'solid',
              fgColor:{ argb: 'cedae3' },
            }
          }

          if(tableHeading[columnIndex].style && cellNum>1 ){
            if(tableHeading[columnIndex].style.bgColor){
              cell.fill = {
                type: 'pattern',  
                pattern: 'solid',
                fgColor:{ argb: tableHeading[columnIndex].style.bgColor},
              }
            }
            if(tableHeading[columnIndex].style.fontColor){
              cell.font = { color: { argb: tableHeading[columnIndex].style.fontColor}}
            }
          }
          // #style cell
        }
      });
        
      // Set minimum width (optional)
      const minWidth = 10;
      // Set column width based on the maximum content length
      column.width = Math.max(minWidth, maxLength + 5); // Add some padding
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: this.EXCEL_TYPE});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}${this.EXCEL_EXTENSION}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

  public  numberToLetters(n) {
    let result = '';

    while (n > 0) {
      let remainder = (n - 1) % 26;  // Adjusting to 0-based indexing
      result = String.fromCharCode(65 + remainder) + result;  // 65 is the ASCII code for 'A'
      n = Math.floor((n - 1) / 26);
    }

    return result;
  }
}
