
import { Injectable } from '@angular/core';
import { GridFilters, GridFiltersParams } from './table-grid.interface';
import { ActivatedRoute } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class TableGridFilterService {
  filters: GridFilters;
  currentRoute: string = '';
  currentRouteExt: string = '';
  constructor(private route: ActivatedRoute) { }

  persistFilters(filters: GridFilters) {
    this.filters = filters;    
  }

  getExistingFilters(): GridFiltersParams {
    if (!this.filters || !Object.keys(this.filters).length) {
      return {
        name: null,
        description: null,
        createdAt: null,
        updatedAt: null,
        firstName: null,
        lastName: null,
        email: null,
        roleDocumentName: null,
        inActiveAt: null,
        deletedAt: null,
        companyName: null,
        website: null,
        mobile: null,
        phoneWork: null,
        street: null,
        postalCode: null,
        city: null,
        state: null,
        country: null,
        title: null,
        siteDomainMasterKeyCode: null,
        listId: null,
        listValue: null,
        server: null,
        userName: null,
        urlparams: null,
        tableSchema: null,
        availableForAddRowAt: null,  
      };
    }
    return <GridFiltersParams>{
      name: JSON.stringify(this.filters.name) && (JSON.parse(JSON.stringify(this.filters.name)) || null),
      description: JSON.stringify(this.filters.description) && (JSON.parse(JSON.stringify(this.filters.description)) || null),
      createdAt: JSON.stringify(this.filters.createdAt) && (JSON.parse(JSON.stringify(this.filters.createdAt)) || null),
      updatedAt: JSON.stringify(this.filters.updatedAt) && (JSON.parse(JSON.stringify(this.filters.updatedAt)) || null),
      firstName: JSON.stringify(this.filters.firstName) && (JSON.parse(JSON.stringify(this.filters.firstName)) || null),
      lastName: JSON.stringify(this.filters.lastName) && (JSON.parse(JSON.stringify(this.filters.lastName)) || null),
      email: JSON.stringify(this.filters.email) && (JSON.parse(JSON.stringify(this.filters.email)) || null),
      roleDocumentName: JSON.stringify(this.filters.roleDocumentName) && (JSON.parse(JSON.stringify(this.filters.roleDocumentName)) || null),
      inActiveAt: JSON.stringify(this.filters.inActiveAt) && (JSON.parse(JSON.stringify(this.filters.inActiveAt)) || null),
      deletedAt: JSON.stringify(this.filters.deletedAt) && (JSON.parse(JSON.stringify(this.filters.deletedAt)) || null),
      companyName: JSON.stringify(this.filters.companyName) && (JSON.parse(JSON.stringify(this.filters.companyName)) || null),
      website: JSON.stringify(this.filters.website) && (JSON.parse(JSON.stringify(this.filters.website)) || null),
      mobile: JSON.stringify(this.filters.mobile) && (JSON.parse(JSON.stringify(this.filters.mobile)) || null),
      phoneWork: JSON.stringify(this.filters.phoneWork) && (JSON.parse(JSON.stringify(this.filters.phoneWork)) || null),
      street: JSON.stringify(this.filters.street) && (JSON.parse(JSON.stringify(this.filters.street)) || null),
      postalCode: JSON.stringify(this.filters.postalCode) && (JSON.parse(JSON.stringify(this.filters.postalCode)) || null),
      city: JSON.stringify(this.filters.city) && (JSON.parse(JSON.stringify(this.filters.city)) || null),
      state: JSON.stringify(this.filters.state) && (JSON.parse(JSON.stringify(this.filters.state)) || null),
      country: JSON.stringify(this.filters.country) && (JSON.parse(JSON.stringify(this.filters.country)) || null),
      title: JSON.stringify(this.filters.title) && (JSON.parse(JSON.stringify(this.filters.title)) || null),
      siteDomainMasterKeyCode: JSON.stringify(this.filters.siteDomainMasterKeyCode) && (JSON.parse(JSON.stringify(this.filters.siteDomainMasterKeyCode)) || null),
      listId: JSON.stringify(this.filters.listId) && (JSON.parse(JSON.stringify(this.filters.listId)) || null),
      listValue: JSON.stringify(this.filters.listValue) && (JSON.parse(JSON.stringify(this.filters.listValue)) || null),
      server: JSON.stringify(this.filters.server) && (JSON.parse(JSON.stringify(this.filters.server)) || null),
      userName: JSON.stringify(this.filters.userName) && (JSON.parse(JSON.stringify(this.filters.userName)) || null),
      urlparams: JSON.stringify(this.filters.urlparams) && (JSON.parse(JSON.stringify(this.filters.urlparams)) || null),
      tableSchema: JSON.stringify(this.filters.tableSchema) && (JSON.parse(JSON.stringify(this.filters.tableSchema)) || null),
      availableForAddRowAt: JSON.stringify(this.filters.availableForAddRowAt) && (JSON.parse(JSON.stringify(this.filters.availableForAddRowAt)) || null),
    };
  }

  clearFilters() {
    this.filters = {
      name: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      firstName: null,
      lastName: null,
      email: null,
      roleDocumentName: null,
      inActiveAt: null,
      deletedAt: null,
      companyName: null,
      website: null,
      mobile: null,
      phoneWork: null,
      street: null,
      postalCode: null,
      city: null,
      state: null,
      country: null,
      title: null,
      siteDomainMasterKeyCode: null,
      listId: null,
      listValue: null,
      type: null,
      server: null,
      userName: null,
      urlparams: null,
      tableSchema: null,
      availableForAddRowAt: null,
    }
  }
}