import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'tde-icon-renderer',
  templateUrl: './icon-renderer.component.html',
  styleUrls: ['./icon-renderer.component.scss']
})
export class IconRendererComponent implements ICellRendererAngularComp {

  public params: any;
  public paramsData: Array<any> = null;
  public text: string = null;

  agInit(params: any): void {
    this.params = params;
    this.text = params.text ? params.value : null;
    this.paramsData = params.list || [params];
  }

  refresh(params: any): boolean {
    return true;
  }

  onClick($event, index) {
    if (this.params.list) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
        type: this.params.list[index].type
      };
      this.params.list[index].onClick(params);
    }
    else {
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.onClick(params);
    }
  }

}
