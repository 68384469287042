import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'tde-checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {

  checked: boolean;
  public params: any;

  agInit(params: any): void {
    this.checked = params.value || false;
    this.params = params;
  }

  refresh(params: any): boolean {
    return true;
  }

  onChange($event) {
    const params = {
      checked: $event.checked,
      rowData: this.params
    };
    this.params.onClick(params);
  }
}
