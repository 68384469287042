import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() { }

  
  // get url
  getUrl() {
    let api: object = environment.apiStatic;
    return `${api["protocol"]}://${api["url"]}${api["endpoint"]}/`;
  }
}
