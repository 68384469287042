import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrMessageService {

  constructor(private toastrService: ToastrService) {
  }

  generateNotification(options, type: string) {
    const defaultOptions = {
      timeOut: 4000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-with-icon',
      positionClass: 'toast-top-right'
    };

    const positionClass: string = (options.from && options.align) ? `toast-${options.from}-${options.align}` : defaultOptions.positionClass;
    const message: string = options.message || '';
    const title: string = options.title || '';

    options = { ...defaultOptions, ...options, ...{ positionClass } }

    switch (type) {
      case 'error':
        this.errorNotification(message, title, options);
        break;
      case 'info':
        this.infoNotification(message, title, options);
        break;
      case 'warning':
        this.warningNotification(message, title, options);
        break;
      case 'success':
        this.successNotification(message, title, options);
        break;
    }
  }


  public triggerNotification(msg: string, msgType: string, icon: string = 'nc-icon'): void {

    // remove full stop if its exist in last of msg
    if (msg && msg.lastIndexOf('.') > -1 && msg.lastIndexOf('.') === (msg.length - 1)) {
      msg = msg.slice(0, -1);
    }


    this.generateNotification({ message: `<span data-notify="icon" class="${icon} nc-bell-55"></span><span data-notify="message">${msg}.</span>` }, msgType);
  }

  private errorNotification(message, title, options) {
    options.toastClass = `${options.toastClass} alert-error`;
    this.toastrService.error(message, title, options);
  }

  private successNotification(message, title, options) {
    options.toastClass = `${options.toastClass} alert-success`;
    this.toastrService.success(message, title, options);
  }

  private warningNotification(message, title, options) {
    options.toastClass = `${options.toastClass} alert-warning`;
    this.toastrService.warning(message, title, options);
  }

  private infoNotification(message, title, options) {
    options.toastClass = `${options.toastClass} alert-info`;
    this.toastrService.info(message, title, options);
  }
}
