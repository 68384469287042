import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OktaLoginService {

  oktaLoginConf;
  constructor(private readonly httpService: HttpService) {
    this.oktaLoginConf = environment.oktaLoginConf;
  }

  getLoginUrl(): string {
    // return `https://${this.oktaLoginConf.domain}/oauth2/v1/authorize?audience=https://${this.oktaLoginConf.domain}/api/v2&scope=${this.oktaLoginConf.scope}&response_type=${this.oktaLoginConf.response_type}&client_id=${this.oktaLoginConf.clientId}&redirect_uri=${this.oktaLoginConf.redirectURL}&connection=${this.oktaLoginConf.connection}&state=${this.oktaLoginConf.state}&code_challenge_method=${this.oktaLoginConf.code_challenge_method}&code_challenge=${this.oktaLoginConf.code_challenge}`;
    return `https://${this.oktaLoginConf.domain}/oauth2/v1/authorize?client_id=${this.oktaLoginConf.clientId}&response_type=code&scope=${this.oktaLoginConf.scope}&redirect_uri=${this.oktaLoginConf.redirectURL}&state=state-8600b31f-52d1-4dca-987c-386e3d8967e9&code_challenge_method=S256`;
  }

  redirectUrl(url: string): void {
    window.location.href = url;
  }


  initLoginProcess() {
    this.redirectUrl(this.getLoginUrl());
  }


  getOktaUserDetails(code: string): Observable<any> {
    // const body = new URLSearchParams();
    // body.set('grant_type', 'authorization_code');
    // body.set('client_id', '0oafjn9ycrdej6EqK5d7');
    // body.set('redirect_uri', 'https://oidcdebugger.com/debug');
    // body.set('code', '5_Dz661MJCMBDUgAQMUwPkGh8ugw5SRDaVCafoszf2A');
    // // body.set('code_verifier', 'M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag');

    // const options = {
    //   headers:
    //     new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded')
    // };

    return this.httpService.post(`authenticate-okta`, {code: code});
  }


}
