import { Component } from "@angular/core";

@Component({
  selector: "tde-customdatecomponent",
  templateUrl: './custom.date.component.html',
})

export class CustomDateComponent  {
  public dateString: string;
  public date: Date;
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  onResetDate() {
    this.setDate(null);
    this.params.onDateChanged();
  }

  onDateChanged(event) {
    this.params.onDateChanged();
  }

  getDate(): Date {
    return this.date;
  }

  setDate(date: Date): void {
    this.date = date;
  }
}
