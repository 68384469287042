import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'ag-grid-header-renderer',
  templateUrl : './header-renderer.component.html',
  styleUrls  : ['./header-renderer.component.scss'],
})
export class HeaderRendererComponent {
  public params: any;

  @ViewChild('menuButton', { read: ElementRef }) public menuButton;

  agInit(params): void {
    this.params = params;
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortRequested(event) {
    if (this.params.enableSorting) {
      this.params.setSort(this.params.column.isSortAscending() ? 'desc' : 'asc', event.shiftKey);
    }
  }
}
