import { Injectable } from '@angular/core';
import { IPrimaryKey } from '../interface/commonModel';
import { getCurrentTimeZone } from '../utils/function';

@Injectable({
  providedIn: 'root'
})
export class TableDataService {
  private tablePrimaryKeys: IPrimaryKey[] = []
  private initialTableData: Array<any> = []
  constructor() { }


  setTablePrimaryKeys(tablePrimaryKeys: IPrimaryKey[]): void {
    this.tablePrimaryKeys = tablePrimaryKeys;
  }


  isPrimaryKeyExist(key: string): boolean {
    if ((this.tablePrimaryKeys || []).length) {
      const primaryKeyModel = this.tablePrimaryKeys.find((primaryKeyModel: IPrimaryKey) => primaryKeyModel.columnName === key)
      return primaryKeyModel != undefined;
    }
    return false;
  }


  getTablePrimaryKeys(): IPrimaryKey[] {
    return this.tablePrimaryKeys;
  }

  setInitialTableData(initialTableData: Array<any>) {
    this.initialTableData = JSON.parse(JSON.stringify(initialTableData));
  }

  getInitialTableData(): Array<any> {
    return this.initialTableData;
  }

  prepareUpdatedData(updatedDataList: Array<any>, columnHeaders): object {
    const newDataList: Array<any> = [];
    const oldDataList: Array<any> = [];
    const updtDataList: Array<any> = [];
    const dateFormat = JSON.parse(sessionStorage.getItem('configSettings')).dateFormat;
    const timeFormat = JSON.parse(sessionStorage.getItem('configSettings')).timeFormat;

    const primaryKeys: IPrimaryKey[] = this.getTablePrimaryKeys();

    updatedDataList.forEach(row => {
      let newDataRow = {};
      let oldDataRow = {};
      let updtDataRow = {};

      const initialRow = this.initialTableData.find(itr => {
        const primaryKeyColumnName = primaryKeys[0].columnName;
        let status: boolean = itr[primaryKeyColumnName] === row[primaryKeyColumnName];
        let i = 1;
        while (status && i < primaryKeys.length) {
          const primaryKeyColumnName = primaryKeys[i].columnName;
          status = status && itr[primaryKeyColumnName] === row[primaryKeyColumnName];
          i++;
        }
        return status;
      });

      // const initialRow = this.initialTableData.find(ir => ir[this.tablePrimaryKey] === row[this.tablePrimaryKey]);
      if (initialRow) {
        Object.keys(initialRow).forEach(key => {
          if (initialRow[key] != row[key]) {
            if (columnHeaders.filter(data => data.field === key)[0].dataType === 'datetime2' || columnHeaders.filter(data => data.field === key)[0].dataType === 'timestamp') {
              oldDataRow[key] = delimiterCheck(initialRow[key]) === 'NDE' ? new Date(initialRow[key]) : toDate(initialRow[key], dateFormat, delimiterCheck(initialRow[key]));
              updtDataRow[key] = delimiterCheck(row[key]) === 'NDE' ? new Date(row[key]) : toDate(row[key], dateFormat, delimiterCheck(row[key]));
              if (updtDataRow[key]) {
                updtDataRow[key] = (updtDataRow[key].toISOString().substring(0, 19)) + '.000' + getCurrentTimeZone();
                // updtDataRow[key] = updtDataRow[key].toISOString();
              }
            } else {
              oldDataRow[key] = initialRow[key];
              updtDataRow[key] = row[key];
            }
          }
        });
      } else {
        newDataRow = row;
      }

      if (Object.keys(updtDataRow).length > 0) {
        Object.keys(updtDataRow).forEach(key => {
          if (typeof (updtDataRow[key]) === 'object') {
            updtDataRow[key] = updtDataRow[key].hasOwnProperty('label') ? updtDataRow[key].value : updtDataRow[key];
          }
        })

        const primaryKeyData = this.getPrimaryKeyRowsData(primaryKeys, row);
        updtDataList.push({ ...updtDataRow, ...primaryKeyData });
        oldDataList.push({ ...oldDataRow, ...primaryKeyData });

      } else if (Object.keys(newDataRow).length > 0) {
        Object.keys(newDataRow).forEach(key => {
          if (typeof (newDataRow[key]) === 'object') {
            newDataRow[key] = newDataRow[key].hasOwnProperty('label') ? newDataRow[key].value : newDataRow[key];
          }
          if (columnHeaders.filter(data => data.field === key)[0].dataType === 'datetime2' || columnHeaders.filter(data => data.field === key)[0].dataType === 'timestamp') {
            newDataRow[key] = delimiterCheck(newDataRow[key]) === 'NDE' ? new Date(newDataRow[key]) : toDate(newDataRow[key], dateFormat, delimiterCheck(newDataRow[key]));
            if (newDataRow[key]) {
              newDataRow[key] = newDataRow[key].toISOString().substring(0, 19) + '.000+00:00';;
            }
          }
        })
        const primaryKeyData = this.getPrimaryKeyRowsData(primaryKeys, row);
        newDataList.push({ ...newDataRow, ...primaryKeyData});
      }
    });
    return { "updateData": updtDataList, "initialData": oldDataList, "newData": newDataList };
  }

  private getPrimaryKeyRowsData(primaryKeys: IPrimaryKey[], row): Object {
    const primaryKeyData = {};
    primaryKeys.forEach(pk => {
      primaryKeyData[pk.columnName] = row[pk.columnName];
    });
    return primaryKeyData;
  }
}



function toDate(date, format, delimiter) {
  var date = date;
  var formatedDate = null;
  var formatLowerCase = format.toLowerCase();
  var formatItems = formatLowerCase.split(delimiter);
  var dateItems = date.split(delimiter);
  var monthIndex = formatItems.indexOf("mm");
  var monthNameIndex = formatItems.indexOf("mmm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var yearShortIndex = formatItems.indexOf("yy");
  var d = dateItems[dayIndex].substring(0, 2);

  var timeParts = evaluateTimeIndex(dateItems, monthIndex, monthNameIndex, dayIndex, yearIndex, yearShortIndex);
  const tempYearIndex = yearIndex > -1 ? yearIndex : yearShortIndex;
  const yearCharLength = yearIndex > -1 ? 4 : 2;
  let year = dateItems[tempYearIndex].substring(0, yearCharLength);
  year = yearCharLength === 2 ? (year <= 70 && year >= 50) ? '19' + year : '20' + year : year;

  if (d < 10) {
    d = "0" + d;
  }
  if (monthIndex > -1) {
    var month = (dateItems[monthIndex].substring(0, 2));
    month -= 1;
    if (month < 10) {
      month = "0" + month;
    }
    formatedDate = new Date(+new Date(year, month, d) + parseDaytime(timeParts));
  } else if (monthNameIndex > -1) {
    var monthName = dateItems[monthNameIndex].substring(0, 3);
    month = getMonthIndex(monthName);
    if (month < 10) {
      month = "0" + month;
    }
    formatedDate = new Date(+new Date(year, month, d) + parseDaytime(timeParts));
  }
  return formatedDate;
};

function delimiterCheck(date) {
  var format = /[\\]/;
  var delimiter = /[\-]/;
  if (format.test(date)) {
    return '/';
  } else if (delimiter.test(date)) {
    return '-';
  } else {
    return 'NDE' /*No Delimiter Exists*/
  }
}


function evaluateTimeIndex(dateItems, monthIndex, monthNameIndex, dayIndex, yearIndex, yearShortIndex) {
  var list = [{ monthIndex, length: '2' }, { monthNameIndex, length: '2' }, { dayIndex, length: '2' }, { yearIndex, length: '4' }, { yearShortIndex, length: '2' }];
  let time;
  Object.keys(list).forEach(key => {
    Object.keys(list[key]).forEach(keys => {
      if (list[key][keys] === 2 && keys !== 'length') {
        const index = list[key][keys];
        const substrlth = list[key]['length'];
        time = dateItems[index].substring(+substrlth + 1);
      }
    })
  })
  return time;
}

function getMonthIndex(name) {
  name = name.toLowerCase();
  if (name == "jan" || name == "january") {
    return 0;
  } else if (name == "feb" || name == "february") {
    return 1;
  } else if (name == "mar" || name == "march") {
    return 2;
  } else if (name == "apr" || name == "april") {
    return 3;
  } else if (name == "may" || name == "may") {
    return 4;
  } else if (name == "jun" || name == "june") {
    return 5;
  } else if (name == "jul" || name == "july") {
    return 6;
  } else if (name == "aug" || name == "august") {
    return 7;
  } else if (name == "sep" || name == "september") {
    return 8;
  } else if (name == "oct" || name == "october") {
    return 9;
  } else if (name == "nov" || name == "november") {
    return 10;
  } else if (name == "dec" || name == "december") {
    return 11;
  }
}

function parseDaytime(time) {
  let [hours, minutes, seconds] = time.substr(0, time.length - 2).split(":").map(Number);
  if (time.includes("PM") && hours !== 12) hours += 12;
  return (seconds !== undefined ? (1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes + (seconds * 1 / 60))) : (1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes)));
}