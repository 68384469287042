import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private urlService: UrlService) {
  }

  post(action, params): Observable<any> {

    const url: string = `${this.urlService.getUrl()}${action}`;
    return this.httpClient.post(url, params).pipe(
      map((res) => res));
  }

  postOutside(action, params, headers = null): Observable<any> {
    return this.httpClient.post(action, params, headers).pipe(
      map((res) => res));
  }



  get(action, params = {}): Observable<any> {
    const url: string = `${this.urlService.getUrl()}${action}`;
    return this.httpClient.get(url, { params: params }).pipe(
      map((res) => res));
  }

  put(action, params = {}): Observable<any> {
    const url: string = `${this.urlService.getUrl()}${action}`;
    return this.httpClient.put(url, params).pipe(
      map((res) => res));
  }

  postInteranl(action, params): Observable<any> {
    const url: string = `${this.urlService.getUrl()}${action}`;
    return this.httpClient.post(url, params).pipe(
      map((res) => res));
  }

  getInternal(action, params = {}): Observable<any> {
    const url: string = `${action}`;
    return this.httpClient.get(url, { params: params }).pipe(
      map((res) => res));
  }
}