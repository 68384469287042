import { Injectable } from '@angular/core';
import { IColDef } from '../../../../interface/col-def.interface';
import { Constants } from '../../../../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AgGridService {

  private numericType: Array<string> = ['int', 'mediumint', 'smallint', 'tinyint', 'bigint'];
  private decimalType: Array<string> = ['decimal', 'float', 'double', 'real'];
  private longTextType: Array<string> = [
    'text', 'mediumtext', 'longtext', 'blob', 'mediumblob', 'longblob', 'ntext', 'binary', 'varbinary', 'image',
    'json', 'jsonb'
  ];


  private tableListUpdateData: any = {};
  private insertedRows: Array<boolean> = [];

  private tableListAgGridConfig = {
    //'paginationSize': 100,
    'sizeToFit': true,
    'contentToFit': false
  }

  private tableListConfig: any = {
    'class': {
      'fullScreen': false
    },
    'id': 'tableList'
  }

  private tableDataAgGridConfig = {
    //'paginationSize': 70,
    'sizeToFit': false,
    'contentToFit': true,
    'getRowClass': (params) => {
      return this.checkRowColor(params);
    }
  }

  private tableDataConfig: any = {
    'class': {
      'fullScreen': false
    },
    'id': 'tableData'
  }

  constructor() { }

  isNumericType(type: string): boolean {
    return this.numericType.indexOf(type.toLowerCase()) > -1;
  }

  isDecimalType(type: string): boolean {
    return this.decimalType.indexOf(type.toLowerCase()) > -1;
  }

  isLongTextType(type: string): boolean {
    return this.longTextType.indexOf(type.toLowerCase()) > -1;
  }

  getTableListAgGridConfig() {
    return this.tableListAgGridConfig;
  }

  getTableListConfig() {
    return this.tableListConfig;
  }

  getTableDataAgGridConfig() {
    return this.tableDataAgGridConfig;
  }

  getTableDataConfig() {
    return this.tableDataConfig;
  }

  getTableListUpdateData() {
    return this.tableListUpdateData;
  }

  setTableListUpdateData(rowData: any): void {
    this.tableListUpdateData[(rowData.id) + ''] = rowData.data;
    this.insertedRows[(rowData.id) + ''] = rowData.data.id === '' ? true : false;
  }

  getInsertedRowCunt() {
    return this.insertedRows;
  }

  resetTableListUpdateData(): void {
    this.tableListUpdateData = {};
    this.insertedRows = [];
  }

  checkRowColor(params): string {
    return null;
  }


  getAgGridDBColumnDef(): Array<IColDef> {
    return [
      {
        headerName: 'Datasource Name',
        field: 'name',
        cellRenderer: 'iconRenderer',
        cellRendererParams: {
          //onClick: this.loadDB.bind(this),
          icons: {
            'title': 'View Table List',
            'iconClass': 'fa fa-eye',
            'buttonTextClass': 'ml-0 pt-2 pb-2 pl-0 pr-2',
            noDefaultPaddding: true
          },
          text: true
        },
        suppressNavigable: true,
        editable: false,
        //suppressCellSelection: true,
        lockPosition: true,
        cellClass: 'no-border',
        width: 200,
        //maxWidth: 200,
        resizable: true,
        minWidth: 200,
        pinned: 'left',
        sortable: true,
        filter: Constants.TEXT_FILTER,
        checkFilterSort: true,
      },
      // {
      //   headerName: 'DB Name',
      //   field: 'name',
      //   cellRenderer: 'linkRenderer',
      //   cellRendererParams: {
      //   },
      //   width: 180,
      //   minWidth: 180,
      //   resizable: true,
      //   filter: 'agTextColumnFilter',
      //   tooltipField: 'name',
      //   pinned: 'left'
      // },
      {
        headerName: 'Datasource Description',
        field: 'description',
        width: 300,
        minWidth: 200,
        resizable: true,
        filter: Constants.TEXT_FILTER,
        tooltipField: 'description',
        sortable: true,
        checkFilterSort: true,
      }
    ]
  }

  getAgGridTableColumnDef(): Array<IColDef> {
    return [
      {
        headerName: 'Table Name',
        field: 'name',
        cellRenderer: 'iconRenderer',
        cellRendererParams: {
          //onClick: this.loadTable.bind(this),
          icons: {
            'title': 'View Data',
            'iconClass': 'fa fa-eye',
            'buttonTextClass': 'ml-0 pt-2 pb-2 pl-0 pr-2',
            noDefaultPaddding: true
          },
          text: true
        },
        suppressNavigable: true,
        editable: false,
        filter: Constants.TEXT_FILTER,
        cellClass: 'no-border',
        width: 250,
        minWidth: 250,
        pinned: 'left',
        lockPosition: true,
        sortable: true,
        checkFilterSort: true,
      },
      // {
      //   headerName: 'Table Name',
      //   field: 'name',
      //   width: 180,
      //   minWidth: 180,
      //   cellRenderer: 'linkRenderer',
      //   resizable: true,
      //   cellRendererParams: {
      //   },
      //   filter: 'agTextColumnFilter',
      //   tooltipField: 'name',
      //   pinned: 'left'
      // },
      {
        headerName: 'Table Description',
        field: 'description',
        width: 400,
        resizable: true,
        filter: Constants.TEXT_FILTER,
        tooltipField: 'description',
        sortable: true,
        checkFilterSort: true,
      }
    ]
  }

  public getHeaderIcon(colType: string): string {
    const colIconPath: string = 'assets/img/icons/';
    if (colType) {
      let icon: string = '';
      switch (colType) {
        case 'decimal':
        case 'float':
        case 'double':
        case 'real':
          icon = `decimal`;
          break;
        case 'int':
        case 'mediumint':
        case 'smallint':
        case 'tinyint':
        case 'bigint':
        case 'int4':
          icon = `number`;
          break;
        case 'datetime':
        case 'timestamp':
        case 'datetime2':
        case 'date':
          icon = `calendar`;
          break;
        default:
          icon = 'string';
      }
      return `${colIconPath}${icon}.svg`;
    }
    else {
      return null;
    }
  }
}
