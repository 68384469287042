
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../guards/auth.service';
import { UrlService } from '../services/url.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

   exceptURLsList = ['authentication'];

  constructor(private authService: AuthService, private urlService: UrlService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const TOKEN: string = this.authService.getToken();
    let authReq = req.clone();
    const requestURL = authReq.url.replace(this.urlService.getUrl(), '');
    
    if (TOKEN && this.exceptURLsList.indexOf(requestURL) === -1) {
      const requestHeaders = req.headers.set('Authorization', `Bearer ${TOKEN}`);
      authReq = req.clone({
        headers: requestHeaders
      });
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }

  getAPIToken() {
    // return DEV_TOKEN;
    // if(environment.production) {
    //     //return this.auth.API_TOKEN;
    // }
    // else {
    //     return DEV_TOKEN;
    // }
  }
}