export const Routes = {
  HOME: {
    URL: '/',
    TITLE: 'Home',
    ROLE: []
  },
  HOME_DATABASE: {
    URL: '/datasource',
    TITLE: 'Datasource',
    ROLE: ['HOME_DATABASE_LIST']
  },
  DB_LIST: {
    URL: '/datasource-configuration',
    TITLE: 'Datasource Config',
    ROLE: ['DATABASE_CONFIG', 'DATABASE_LIST', 'DATABASE_ADD']
  },
  AUDIT_REPORT: {
    URL: '/audit-view',
    TITLE: 'Audit View',
    ROLE: ['AUDIT_VIEW']
  },
  USERS: {
    URL: '/user',
    TITLE: 'User',
    ROLE: ['USER_LIST', 'USER_ADD']
  },
  COMPANY: {
    URL: '/company',
    TITLE: 'Company',
    ROLE: ['COMPANY_ADD', 'COMPANY_LIST']
  },
  COMPANY_ADD: {
    PAGE_TYPE: 'add',
    URL: '/company/add',
    TITLE: 'Add Company',
    ROLE: ['COMPANY_ADD']
  },
  COMPANY_EDIT: {
    PAGE_TYPE: 'edit',
    URL: '/company/edit/:companyId',
    TITLE: 'Edit Company',
    ROLE: ['COMPANY_EDIT']
  },
  COMPANY_SELF_EDIT: {
    PAGE_TYPE: 'ownEdit',
    URL: '/company/edit',
    TITLE: 'Edit Company',
    ROLE: ['COMPANY_SELF_EDIT']
  },
  COMPANY_SETTINGS_EDIT: {
    PAGE_TYPE: 'edit',
    URL: '/company/settings/:companyId',
    TITLE: 'Company Settings',
    ROLE: ['COMPANY_SETTINGS_EDIT']
  },
  COMPANY_SETTINGS_SELF_EDIT: {    
    PAGE_TYPE: 'ownEdit',
    URL: '/company/settings',
    TITLE: 'Company Settings',
    ROLE: ['COMPANY_SETTINGS_SELF_EDIT']
  },
  COMPANY_LIST_USER: {
    URL: '/users/:companyId',
    TITLE: 'Company User',
    ROLE: ['COMPANY_LIST_USER']
  },
  ROLE: {
    URL: '/role',
    TITLE: 'Role',
    ROLE: ['ROLE_ADD', 'ROLE_LIST']
  },
  ROLE_ADD: {
    URL: '/role/add',
    TITLE: 'Add Role',
    ROLE: ['ROLE_ADD']
  },
  ROLE_EDIT: {
    URL: '/role/edit/:id',
    TITLE: 'Edit Role',
    ROLE: ['ROLE_EDIT']
  },
  LOGOUT: {
    URL: '/logout',
    ROLE: []
  },
  CHANGE_PASSWORD: {
    URL: '/change-password',
    TITLE: 'Change Password',
    ROLE: []
  },
  DB_CHANGE_PASSWORD: {
    URL: '/datasource-configuration/db/:dbId/changePassword',
    TITLE: 'Datasource Change Password',
    ROLE: ['DATABASE_CHANGE_PASSWORD']
  },
  DATABASE_TABLE: {
    URL: '/datasource-configuration/db/:dbId',
    TITLE: 'Datasource Table',
    ROLE: ['DATABASE_CONFIG','DATABASE_LIST', 'DATABASE_ADD']
  },
  DATABASE_RESOURCE_ACCESS: {
    URL: '/datasource-configuration/resource-access-db/:dbId',
    TITLE: 'Datasource Resource Access',
    ROLE: ['DATABASE_TABLES_FIELDS']
  },
  
  DATABASE_TABLES_FIELDS: {
    URL: '/datasource-configuration/db/:dbId/table/:tableId/columns',
    TITLE: 'Datasource Table Fields',
    ROLE: ['DATABASE_TABLES_FIELDS']
  },
  DATABASE_TABLES_COLUMNS_LOVS: {
    URL: '/datasource-configuration/db/:dbId/table/:tableId/columns/lovs/:columnId',
    TITLE: 'Datasource Tables Columns LOVs',
    ROLE: ['DATABASE_TABLES_COLUMNS_LOVS']
  },
  LOV: {
    URL: '/lov',
    TITLE: 'List of Values',
    ROLE: ['LOV_LIST']
  },
  LOV_DATA_LIST: {
    URL: '/lov/:lovId/data/list',
    TITLE: 'List of Values Data List',
    ROLE: ['LOV_DATA_LIST']
  },
};
