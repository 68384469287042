import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Routes } from '../routePath/route';
import { ToastrMessageService } from '../services/toastr.service';
import { AuthService } from './auth.service';
import { TableGridFilterService } from '../component/ag-grid/table-grid/table-grid-filter.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  navigationEnd: Observable<NavigationEnd>;
  routePathParam: Observable<String>;
  constructor(
    private authService: AuthService,
    private toastrMessageService: ToastrMessageService,
    private router: Router,
    private route: ActivatedRoute,
    private tableGridFilterService: TableGridFilterService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const filterInterfaceCurrentRouteExtValidToClear = this.tableGridFilterService.currentRoute === 'datasource-configuration' ?
    this.tableGridFilterService.currentRouteExt !== (next.data && next.data.URL && next.data.URL.split("/")[3])? true: false: false

    if((this.tableGridFilterService.currentRoute !== (next.data && next.data.URL && next.data.URL.split("/")[1])) || filterInterfaceCurrentRouteExtValidToClear) {
      this.tableGridFilterService.clearFilters();
    }
    const userRoles: string[] = this.authService.getRoleList();
    const routes = Object.values(Routes);
    const currentURL = routes.filter(route => route.URL === state.url);
    let roles = (currentURL && currentURL[0] && currentURL[0].ROLE) || [];
    if (next.data.ROLE) {
      roles = next.data.ROLE;
    }
    if (roles instanceof Array && roles.length === 0) {
      return true;
    }
    else {
      const checkRoleAccess: string[] = roles.filter(role => userRoles.indexOf(role) > -1);
      if (checkRoleAccess.length === 0) {
        this.toastrMessageService.triggerNotification('You are not authorized user to access this', 'error');
        if (state.url !== '/') {
           this.router.navigate(['/']);
        }
      }
      return checkRoleAccess.length > 0;
    }
  }
}
