<div class="ag-custom-header">
    <div (click)="onSortRequested($event)" [class]="params?.headerClass">
        <div *ngIf="params?.menuIcon || params?.menuImg" class="ag-grid-header-menu-icon">
            <i *ngIf="params?.menuIcon" class="fa fa-{{ params?.menuIcon }}"></i>
            <img *ngIf="params?.menuImg" class="img" [src]="params?.menuImg" />
        </div>
        <div class="ag-grid-header-label"> {{ params.displayName }}</div>
        <div *ngIf="params?.enableSorting || params.column.isFilterActive()" class="customSortDownLabel">
            <ng-container *ngIf="params?.enableSorting">
                <span class="ag-icon ag-icon-desc" *ngIf="params.column.isSortDescending()"></span>
                <span class="ag-icon ag-icon-asc" *ngIf="params.column.isSortAscending()"></span>
            </ng-container>
            <span class="ag-icon ag-icon-filter" *ngIf="params.column.isFilterActive()"></span>
        </div>
    </div>
    <div>
        <span class="ag-icon ag-icon-menu" unselectable="on" #menuButton (click)="onMenuClicked()"></span>
     </div>
</div>