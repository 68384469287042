export const Constants = {
    DATE_FORMAT: 'MMM d, y',
    TIME_FORMAT: 'h:mm a',
    DATE_TIME_FORMAT: 'MMM d, y h:mm a',
    TEXT_FILTER: 'agTextColumnFilter',
    DATE_FILTER: 'agDateColumnFilter',
    NUMBER_FILTER: 'agNumberColumnFilter',
    DEFAULT_PAGINATION_SIZE: 20,
    MSSQL_DEFAUL_PRIMARY_KEY: "##ROWID##"
}

export const LoginList = {
    LOGINTYPES: [
        { label: 'Allow Email Login', name: 'isLoginEmailAllowed', iconClass: 'fas fa-envelope', tooltip: 'Email Login' },
        { label: 'Allow MS Login', name: 'isLoginMSAllowed', iconClass: 'fab fa-microsoft', tooltip: 'Microsoft Login' },
        { label: 'Allow Okta Login', name: 'isLoginOktaAllowed', iconClass: 'icon-okta', tooltip: 'Okta Login' },
        // {label: 'Allow Google Login', name: 'isLoginGoogleAllowed', iconClass: 'fab fa-google', tooltip: 'Google Login'},
    ]
}