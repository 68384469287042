<!-- [paginationPageSize]="paginationSize" -->
<div #agGridContainer [ngClass]="{'no-pagination' : !paginationSize}">
    <ag-grid-angular *ngIf="frameworkComponents" #agGrid style="width: 100%;" [ngStyle]="{'height': maxHeight}"
        id="myGrid" 
        class="ag-theme-alpine" 
        [rowData]="tableList?.rowData" 
        suppressMovable="true"
        suppressSizeToFit="true"
        [suppressDragLeaveHidesColumns]="false"
        [columnDefs]="tableList?.columnsDef" 
        multiSortKey="ctrl"
        [pagination]="pagination" 
        [paginationAutoPageSize]="paginationAutoPageSize"
        
        [frameworkComponents]="frameworkComponents"
        [rowDragManaged]="true"
        [animateRows]="true"
        [gridOptions]="gridOptions"
        [paginationPageSize]="paginationSize"
        [defaultColDef]="defaultColDef"  [enableFillHandle]="true"
        [enableRangeSelection]="true" [undoRedoCellEditing]="true" [frameworkComponents]="frameworkComponents"
        [enableRangeHandle]="true" [enableSorting] ="true"
        [components]="components"
        [singleClickEdit]="true"
        [rowSelection]="'multiple'"      
        [suppressExcelExport]="true"
        [enableCellTextSelection]="true"
        [processCellForClipboard]="processCellForClipboard"
        [processHeaderForClipboard]="processHeaderForClipboard"
        [processCellFromClipboard]="processCellFromClipboard"
        [suppressCopyRowsToClipboard]="true"
        [undoRedoCellEditingLimit]="undoRedoCellEditingLimit"
        [enableCellChangeFlash]="true" 
        (cellValueChanged)="onCellValueChanged($event)"
        (rowSelected)="onRowSelected($event)"
        (selectionChanged)="onSelectionChanged($event)"
        [noRowsOverlayComponent]="noRowsOverlayComponent"
        (gridReady)="onGridReady($event)"
        (modelUpdated)="onModelUpdated($event)"
        [suppressCellSelection]="true"     
        
        [suppressRowClickSelection]="true">
    </ag-grid-angular>
</div>