import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'tde-msg-notification',
  templateUrl: './msg-notification.component.html',
  styleUrls: ['./msg-notification.component.scss']
})
export class MsgNotificationComponent implements OnInit {
  
  @Input() input;
  @Output() closeNotification: EventEmitter<any> = new EventEmitter();
  public alertClass;
  public msg;
  constructor() { 
  }
  ngOnInit(): void {
    switch (this.input.type) {
      case 'error':
        this.alertClass = 'alert-danger';
        this.msg = this.input.message;
        break;
      case 'info':
        this.alertClass = 'alert-info';
        this.msg = this.input.message;
        break;
      case 'warning':
        this.alertClass = 'alert-warning';
        this.msg = this.input.message;
        break;
      case 'success':
        this.alertClass = 'alert-success';
        this.msg = this.input.message;
        break;
    }
  }
  close(){
    this.closeNotification.emit(false);
  }
}
